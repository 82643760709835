<template>
  <div>
    <div class="super">
      <div class="holder">
        <Header />
        <div class="contact">
          <div class="container">
            <div class="row">
              <div class="col-md-6">
                <div class="contact__us">
                  <h3 class="contactus-text">Contact Us</h3>
                  <p>Let’s know how we can help you</p>
                  <div class="contact__img">
                    <a href="https://api.whatsapp.com/send?phone=2348061796909"
                      ><img
                        src="../assets/images/whatsApp-blue.svg"
                        alt="whatsapp"
                    /></a>
                    <a href="tel:2348061796909"
                      ><img
                        src="../assets/images/phone-conect.svg"
                        alt="telephone"
                    /></a>
                    <a href="mailto:hello@myshelta.com"
                      ><img src="../assets/images/envelop-blue.svg" alt="eamil"
                    /></a>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="contact_formcontactus_image">
                  <img src="../assets/images/contactus-img.svg" alt="" />
                </div>
              </div>
            </div>
            <!-- Card section -->
            <div class="cardd">
              <div class="row">
                <div class="col-md-4">
                  <div class="box">
                    <h4>Speak to our rep.</h4>
                    <p>
                      Got a question about Shelta Properties? Our sales team
                      will be more than happy to talk to you.
                    </p>
                    <h5>08061796909</h5>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="blue-box box">
                    <h4>Inquiries</h4>
                    <p>
                      Stay in touch with our team to learn more about Shelta
                      properties and our media engagement.
                    </p>
                    <h5>hello@myshelta.com</h5>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="box">
                    <h4>Maintenance</h4>
                    <p>
                      Contact our 24/7 maintenance team for any assistance in
                      maintenance and repair.
                    </p>
                    <h5>08061796909</h5>
                  </div>
                </div>
              </div>
            </div>
            <!-- Card section ends -->

            <!-- Form begins -->
            <div class="form__holder" id="contact_form">
              <h3>Say Hello</h3>
              <form>
                <div class="form-group subject">
                  <label for="subject">Subject</label>
                  <select class="form-control" id="subject" v-model="subject">
                    <option>Rent a property</option>
                    <option>Manage a property</option>
                    <!-- <option>Just saying hi</option> -->
                    <option>Give feedback</option>
                  </select>
                  <!-- {{subject}} -->
                </div>

                <div class="form-row">
                  <div class="form-group col-md-6 formgroup__right">
                    <label for="fname">First Name</label>
                    <input
                      type="text"
                      class="form-control"
                      id="fname"
                      autocomplete="on"
                      v-model="firstname"
                      name="firstname"
                    />
                  </div>
                  <div class="form-group col-md-6 formgroup__left">
                    <label for="lname">Last Name</label>
                    <input
                      type="text"
                      class="form-control"
                      id="lname"
                      autocomplete="on"
                      v-model="lastname"
                      name="lastname"
                    />
                  </div>
                </div>

                <div class="form-row">
                  <div class="form-group col-md-6 formgroup__right">
                    <label for="email">Email Address</label>
                    <input
                      type="text"
                      class="form-control"
                      id="email"
                      autocomplete="on"
                      v-model="email"
                      name="email"
                    />
                  </div>
                  <div class="form-group col-md-6 formgroup__left">
                    <label for="phone">Phone Number</label>
                    <input
                      type="text"
                      class="form-control"
                      id="phone"
                      autocomplete="on"
                      v-model="phone"
                      name="phone"
                    />
                  </div>
                </div>

                <div class="form-group textarea__holder">
                  <label for="message">Message</label>
                  <textarea
                    class="form-control"
                    id="message"
                    rows=""
                    autocomplete="on"
                    v-model="message"
                    name="message"
                  ></textarea>
                </div>

                <div v-if="errors.length">
                  <!-- <p class="errorHeader">Please correct the error(s):</p> -->
                  <ul class="error-ul">
                    <li
                      v-for="error in errors"
                      :key="error.error"
                      class="errorMessage"
                    >
                      {{ error }}
                    </li>
                  </ul>
                </div>

                <div class="send__now">
                  <button class="btn" @click.prevent="feedback()">
                    Send Now
                    <i
                      class="fa fa-spinner fa-spin ml-3 f-12 btn-loader"
                      v-if="loader"
                    ></i>
                  </button>
                </div>
              </form>
            </div>
            <!-- Form ends -->

            <!-- Map area -->
            <!-- AIzaSyBVd5-_gtuth4g1D28RY4xzNvgz94_2uss -->
            <!-- Map area -->
          </div>
        </div>
      </div>
      <FooterMain />
    </div>
  </div>
</template>

<script>
import FooterMain from "../views/FooterMain";
import Header from "../views/Header";
import { Service } from "../store/service";
const Api = new Service();

export default {
  name: "ContactUs",
  components: {
    Header,
    FooterMain
  },
  data() {
    return {
      loader: false,
      errors: [],
      subject: "",
      firstname: "",
      lastname: "",
      email: "",
      message: "",
      phone: ""
    };
  },
  mounted() {
    // this.fetchProperties();
  },
  methods: {
    feedback: function() {
      this.loader = true;
      if (this.subject === "") {
        this.errors.push("Subject is required!");
        this.loader = false;
      }
      if (this.firstname === "") {
        this.errors.push("First name is required!");
        this.loader = false;
      }
      if (this.lastname === "") {
        this.errors.push("Last name is required!");
        this.loader = false;
      }
      if (this.email === "") {
        this.errors.push("Email name is required!");
        this.loader = false;
      }
      if (this.phone === "") {
        this.errors.push("Phone name is required!");
        this.loader = false;
      }
      if (this.message === "") {
        this.errors.push("Message is required!");
        this.loader = false;
      } else {
        Api.postRequest(`processcontactus`, {
          subject: this.subject,
          firstname: this.firstname,
          lastname: this.lastname,
          email: this.email,
          message: this.message,
          phone: this.phone
        })
          .then(res => {
            this.loader = false;
            this.$store.commit("setApiSuccess", "Success");
            (this.subject = ""),
              (this.firstname = ""),
              (this.lastname = ""),
              (this.email = ""),
              (this.message = ""),
              (this.phone = ""),
              console.log("Feedback sent", res);
          })
          .catch(err => {
            this.loader = false;
            return this.$store.commit("setApiFailed", res.data.error);
            console.log(err);
          });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
$primary: #0033ea;
$black: #000000;
$tertiary: #a2b0e3;
$whiteText: #ffffff;
$fontFamily: linernear
  url("https://fonts.googleapis.com/css2?family=Gotham:wght@100&display=swap");

@font-face {
  font-family: "Gotham-bold";
  src: local("Gotham-bold"),
    url("../assets/fonts/shelta_fonts/Gotham\ Bold.otf") format("truetype");
}
@font-face {
  font-family: "Gotham";
  src: local("Gotham"),
    url("../assets/fonts/shelta_fonts/Gotham\ Book.otf") format("truetype");
}
@font-face {
  font-family: "Gotham-medium";
  src: local("Gotham-medium"),
    url("../assets/fonts/shelta_fonts/Gotham\ Medium.otf") format("truetype");
}
.supper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.holder {
  flex-grow: 1;
  // min-height: 100vh;
}

// Contact part
.contact {
  padding-top: 7rem;
  padding-bottom: 4rem;
  text-align: left;
  .contactus-text {
    font-family: Gotham;
    font-style: normal;
    font-weight: 500;
    font-size: 42px;
    line-height: 22px;
    color: $primary;
    padding-bottom: 0.5rem;
  }
}

.contact__us {
  padding-top: 10rem;
  p {
    font-family: Gotham;
    font-style: normal;
    font-weight: normal;
    font-size: 30px;
    line-height: 52px;
    color: $black;
  }
}

.contact__img {
  a:first-child {
    img {
      width: 3.5rem;
      padding-left: 0;
    }
  }
  a {
    img {
      padding: 0 13px;
    }
  }
}
.contact__img {
  a {
    padding: 0 13px;
  }
}
.contactus_image {
  img {
    width: 100%;
  }
}
// Contact us page

// Card section
.cardd {
  margin-top: 6rem;
  margin-bottom: 6rem;
}

.box {
  background: rgba(240, 242, 254, 0.5);
  border-radius: 10px;
  text-align: center;
  padding: 15px 38px;
  margin-bottom: 1.5rem;
  h4 {
    font-family: Gotham;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 15px;
    color: rgba(0, 0, 0, 0.8);
    padding-bottom: 0.7rem;
  }
  p {
    font-family: Gotham;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: rgba(0, 0, 0, 0.8);
  }
  h5 {
    font-family: Gotham;
    font-style: normal;
    font-weight: 500;
    font-size: 25px;
    line-height: 30px;
    color: rgba(0, 0, 0, 0.8);
  }
}
.blue-box {
  background-color: $primary;
  color: $whiteText !important;
  h4,
  p,
  h5 {
    color: $whiteText;
  }
}
// Card section

// Form part
.form__holder {
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 46px 49px;
  h3 {
    font-family: Gotham;
    font-style: normal;
    font-weight: 500;
    font-size: 45px;
    line-height: 46px;
    color: #0033ea;
    padding-bottom: 2rem;
  }
}

.subject {
  label {
    font-family: Gotham;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 17px;
    color: $black;
  }
  select {
    border: 0;
    border-bottom: 2px solid #c4c4c4;
    border-radius: 0;
    font-family: Gotham;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 15px;
    color: rgba(0, 0, 0, 0.7);
    box-shadow: none;
  }
}

.form-row {
  padding-top: 2rem;
}

.formgroup__right {
  padding-right: 30px;
}
.formgroup__left {
  padding-left: 30px;
}

.form-group {
  label {
    font-family: Gotham;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 17px;
    color: $black;
  }
  input {
    border: 0;
    border-bottom: 2px solid #c4c4c4;
    border-radius: 0;
    font-family: Gotham;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 15px;
    box-shadow: none;
    color: rgba(0, 0, 0, 0.7);
  }
}

.textarea__holder {
  padding: 2rem 10px;
  label {
    padding-bottom: 1rem;
  }
  textarea {
    min-height: 12rem;
  }
}

.send__now {
  display: flex;
  justify-content: flex-end;
  button {
    background: $primary;
    color: $whiteText;
    font-family: Gotham;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 17px;
    // display: flex;
    align-items: center;
    width: 165px;
    height: 50px;
  }
}

.errorMessage {
  font-family: $fontFamily;
  font-weight: normal;
  color: #dc3545;
  font-size: 14px;
  list-style: none;
  text-align: center;
  padding-inline-start: 0;
}
.error-ul {
  padding-inline-start: 0;
}
// Form part

// Map area

// Map area

::-webkit-input-placeholder {
  /* Edge */
  font-family: Gotham;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 15px;
  color: rgba(0, 0, 0, 0.7);
}

:-ms-input-placeholder {
  /* Internet Explorer */
  font-family: Gotham;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 15px;
  color: rgba(0, 0, 0, 0.7);
}

::placeholder {
  font-family: Gotham;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 15px;
  color: rgba(0, 0, 0, 0.7);
}

@media only screen and (max-width: 599px) {
  .contact__us {
    padding-top: 2rem;
    padding-bottom: 4rem;
    p {
      font-size: 18px;
      line-height: 41px;
    }
  }
  .contact {
    margin-bottom: 2rem;
    .contactus-text {
      font-size: 30px;
      padding-bottom: 0;
    }
  }
  .contact__img {
    a {
      padding: 0 4px;
    }
  }
  .contact__img {
    a:first-child {
      img {
        width: 2.8rem;
      }
    }
  }
  .contact__img {
    a {
      img {
        width: 2.5rem;
      }
    }
    a:nth-child(2) {
      img {
        width: 3.2rem;
      }
    }
    a:nth-child(3) {
      img {
        width: 3.2rem;
      }
    }
  }
  .contact_formcontactus_image {
    img {
      width: 100%;
    }
  }
  .cardd {
    margin-bottom: 2rem;
  }
  .box {
    h5 {
      font-size: 21px;
    }
  }
  .form__holder {
    padding: 20px 10px;
    h3 {
      font-size: 30px;
      padding-bottom: 1rem;
    }
  }
  .formgroup__right {
    padding-right: 5px;
  }
  .formgroup__left {
    padding-left: 5px;
  }
  .textarea__holder {
    padding: 1rem 1px;
    textarea {
      min-height: 8rem;
    }
  }
  .send__now {
    button {
      width: 100%;
      height: 40px;
    }
  }
}
</style>
